import React, { createContext, useEffect } from 'react'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'

import { defaultState } from './defaultState'
import headerStore, { initHeaderStoreAction, setCartItemsAction } from './store'

import Root from './components/Root'
import { ThemeProvider } from 'styled-components'
import { theme } from '../styles/theme'

export const Context = createContext(defaultState)

export default function App({ state }) {
  headerStore.dispatch(initHeaderStoreAction(state))

  useEffect(() => {
    document.addEventListener('cartItemsChange', (e) => {
      const headerState = headerStore.getState()
      const cartItems = headerState.cartItems
      const newCartItems = []
      const { productId, unitPrice, quantity } = e.detail

      for (const cartItem of cartItems) {
        if (cartItem.removeLink.includes(productId)) {
          cartItem.amount = quantity
          cartItem.price = quantity * unitPrice

          if (quantity === 0) continue
        }

        newCartItems.push(cartItem)
      }

      headerStore.dispatch(setCartItemsAction(newCartItems))
    })
  }, [])

  return (
    <Provider store={headerStore}>
      <ThemeProvider theme={theme}>
        <Root />
      </ThemeProvider>
    </Provider>
  )
}

App.propTypes = {
  materialUI: PropTypes.string,
  state: PropTypes.shape({
    authenticityToken: PropTypes.string,
    onPlatform: PropTypes.string,
    fullpath: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
      admin: PropTypes.bool,
    }),
    cartItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
        price: PropTypes.number,
        amount: PropTypes.number,
        picture: PropTypes.string,
        removeLink: PropTypes.string,
      })
    ),
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            path: PropTypes.string,
            children: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string,
                path: PropTypes.string,
              })
            ),
          })
        ),
      })
    ),
  }),
}
