import { createStore } from 'redux'

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_HEADER_STORE:
      return action.payload
    case SET_CART_ITEMS:
      return { ...state, cartItems: action.payload }
    case SHOW_BANNER_ACTION:
      return {
        ...state,
        customStore: { ...state.customStore, showBanner: action.payload },
      }
    default:
      return state
  }
}

const defaultState = {
  authenticityToken: '',
  cartItems: [],
  currency: {},
  fullpath: '',
  isMobile: false,
  location: '',
  menu: [],
  onPlatform: null,
  phone: '',
  user: {},
  isStoreApp: true,
  customStore: {
    productsCount: 0,
    owner: {},
    bannerBackgroundImage: '',
    newDesign: false,
    ownerAvatarUrl: '',
    customStoreName: '',
    storeSubdomain: '',
    showBanner: true,
  },
}

const SET_CART_ITEMS = 'SET_CART_ITEMS'
const INIT_HEADER_STORE = 'INIT_HEADER_STORE'
const SHOW_BANNER_ACTION = 'SHOW_BANNER_ACTION'

export const setCartItemsAction = (payload) => ({
  type: SET_CART_ITEMS,
  payload,
})

export const initHeaderStoreAction = (payload) => ({
  type: INIT_HEADER_STORE,
  payload,
})

export const showBannerAction = (payload) => ({
  type: SHOW_BANNER_ACTION,
  payload,
})

const store = createStore(reducer)

export default store
