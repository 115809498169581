import React, {useContext, useMemo} from "react";
import PropTypes from "prop-types";
import {removeItem} from "../../../../services/cart";
import CloseIcon from "../../../common/Icons/CloseIcon";
import {Context} from "../../index";

import styles from "./cart-item.module.scss";
import GenericButton from "../../../common/GenericButton";

export function CartItem({
  item,
  item: {picture, type, name, price, amount, removeLink}
}) {
  const {authenticityToken} = useContext(Context);
  return useMemo(
    () => (
      <div className={styles.item}>
        <GenericButton
          onClick={() => removeItem(authenticityToken, removeLink, item)}
          className={styles.itemRemoveButton}
          icon
        >
          <CloseIcon />
        </GenericButton>
        <div className={styles.itemPicture}>
          <img loading="lazy" src={picture} alt={`${type} ${name}`} />
        </div>
        <div className={styles.itemContent}>
          <div className={styles.itemType}>{type}</div>
          <div className={styles.itemName}>{name}</div>
          <div className={styles.itemInfo}>
            <span className={styles.itemPrice}>{price} ₽</span>
            <span className={styles.itemAmount}>{amount} шт.</span>
          </div>
        </div>
      </div>
    ),
    [amount, authenticityToken, item, name, picture, price, removeLink, type]
  );
}

CartItem.propTypes = {
  item: PropTypes.object
};
