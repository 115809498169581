import {useEffect} from "react";

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    if (ref) {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener(`click`, listener);
      return () => {
        document.removeEventListener(`click`, listener);
      };
    }
  }, [ref, handler]);
}
