import React, {useContext} from "react";
import {Context} from "../../index";
import {GeoPicker} from "../GeoPicker";
import {User} from "../User";
import {PaneMenu} from "../PaneMenu";
import PhoneRussiaLink from '../PhoneRussiaLink';

import styles from "./sidebar-pane.module.scss";

export function SidebarPane() {
  const {user} = useContext(Context);

  return (
    <div className={styles.pane}>
      <div className={styles.container}>
        {user.name && <User />}
        <ul className={styles.menu}>
          {!user.name && (
            <>
              <li>
                <a href="/sign_in">Войти</a>
              </li>
              <li>
                <a href="/sign_up">Регистрация</a>
              </li>
            </>
          )}
          <li>
            <PhoneRussiaLink />
          </li>
          <PaneMenu />
          <li>
            <GeoPicker />
          </li>
        </ul>
      </div>
    </div>
  );
}
