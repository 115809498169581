import {removeCartItem} from "../api/common";

export function removeItem(authenticityToken, link, {id, amount}) {
  window.flocktory = window.flocktory || [];
  window.flocktory.push([
    `removeFromCart`,
    {
      item: {
        id,
        count: amount
      }
    }
  ]);

  fetch(removeCartItem(authenticityToken, link)).then(() =>
    window.location.reload()
  );
}
