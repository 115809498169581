import React from 'react'

const CartIcon = () => (
  <svg width="24" height="24" viewBox="0 0 28 28">
    <path
      fill="currentColor"
      d="M26 7H8L6.8 2H2v2.001h3.038L6.8 11c0 7.833 3.603 8 4.8 8h11.404v-2H11.6c-1.8 0-1.616-1.178-1.616-1.595 0-.279.14-.582.791-.727L25 13l1-6zm-5.5 14a2.468 2.468 0 0 0-2.5 2.5c0 1.406 1.094 2.5 2.5 2.5s2.5-1.094 2.5-2.5-1.094-2.5-2.5-2.5zm-9 0A2.468 2.468 0 0 0 9 23.5c0 1.406 1.094 2.5 2.5 2.5s2.5-1.094 2.5-2.5-1.094-2.5-2.5-2.5z"
    />
  </svg>
)

export default CartIcon
