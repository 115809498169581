import React, { useContext, useMemo } from 'react'
import { Context } from '../../index'
import { pathToPage } from '../../../../utils/path-to-page'

export function PaneMenu({ isCustomStore }) {
  const {
    paneMenu,
    user: { hasCustomStore },
  } = useContext(Context)

  return useMemo(
    () =>
      paneMenu.map((item) => {
        const { label, url } = item

        if (
          item.hasOwnProperty(`hasCustomStore`) &&
          hasCustomStore !== item.hasCustomStore
        ) {
          return
        }

        if (isCustomStore && url === '/my_store/products') return

        return (
          <li key={label + url}>
            <a href={url} style={{ color: '#949899' }}>
              {label}
            </a>
          </li>
        )
      }),
    [hasCustomStore, paneMenu]
  )
}
