import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'
import loadable from '@loadable/component'
import classnames from "classnames";
import {HeaderWrapper} from "../HeaderWrapper";
import {Cart} from "../Cart";
import SearchIcon from "../../../common/Icons/SearchIcon";
import CloseIcon from "../../../common/Icons/CloseIcon";
import {isMobileSelector} from "$common/selectors";

import styles from "./sticky-header.module.scss";
import headerStyles from "../Header/header.module.scss";
import logo from "../../../../assets/images/logo.svg";
import GenericButton from "../../../common/GenericButton";

const Search = loadable(() => import('../Search'))

export function StickyHeader() {
  const [isShowing, setIsShowing] = useState(false);
  const [searchMode, setSearchMode] = useState(false);

  const isMobile = useSelector(isMobileSelector)

  useEffect(() => {
    function onScroll() {
      if (window.pageYOffset > 100) {
        setIsShowing(true);
      } else {
        setIsShowing(false);
      }
    }
    document.addEventListener(`scroll`, onScroll);
    return () => document.removeEventListener(`scroll`, onScroll);
  }, [isMobile]);

  return (
    <HeaderWrapper
      className={classnames(
        styles.sticky,
        isShowing && !isMobile && styles.show
      )}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          <a href="/" className={styles.logo}>
            <img loading="lazy" src={logo} alt="Printio" />
          </a>
          {searchMode && <Search isSticky isShowing={isShowing} />}
        </div>
        <div className={styles.right}>
          {!searchMode ? (
            <GenericButton
              onClick={() => setSearchMode(true)}
              className={headerStyles.searchButton}
              icon
            >
              <SearchIcon />
            </GenericButton>
          ) : (
            <GenericButton
              onClick={() => setSearchMode(false)}
              className={headerStyles.closeSearchButton}
              icon
            >
              <CloseIcon />
            </GenericButton>
          )}
          <Cart forceClosed={!isShowing} />
        </div>
      </div>
    </HeaderWrapper>
  );
}
