import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {CartItem} from "../CartItem";

export function CartList({items}) {
  return useMemo(
    () => items.map((item) => <CartItem key={item.id} item={item} />),
    [items]
  );
}

CartList.propTypes = {
  items: PropTypes.array
};
