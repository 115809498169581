import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isMobileSelector } from '$common/selectors'

import styles from './sub-menus.module.scss'
import chevronIcon from '../../assets/chevron.svg'

function ThirdLevelMenu({ menu, focusIndex, menuIndex, isCustomStore }) {
  let bgImage, menuBgImageAccounted

  if (menu.children[0] && menu.children[0].title === 'bg_image') {
    bgImage = menu.children[0]
    menuBgImageAccounted = menu.children.slice(1)
  } else {
    menuBgImageAccounted = menu.children.slice()
  }

  return (
    <div
      className={classnames(styles.subMenuListWrapper, {
        [styles.active]: focusIndex === menuIndex,
      })}
    >
      <div className={styles.subMenuListBgWhite}></div>
      {bgImage && (
        <img
          loading="lazy"
          src={bgImage.path}
          className={styles.subMenuListBgImage}
        />
      )}
      <ul
        className={classnames(styles.subMenuList, {
          [styles.subMenuListOther]: menu.isOther,
        })}
      >
        {menuBgImageAccounted.map((menuItem) => {
          return menu.isOther ? (
            <FourthLevelMenu
              key={menuItem.title + menuItem.path}
              menu={menuItem}
            />
          ) : (
            <ThirdLevelMenuItem
              key={menuItem.title + menuItem.path}
              menuItem={menuItem}
              isCustomStore={isCustomStore}
            />
          )
        })}
      </ul>
    </div>
  )
}

function ThirdLevelMenuItem({ menuItem, isCustomStore }) {
  const { title, path } = menuItem

  const getPathForStoreLink = (path) =>
    isCustomStore ? path.split('store')[1] : path

  let customPath = getPathForStoreLink(path)

  return (
    <li>
      <a href={customPath}>{title}</a>
    </li>
  )
}

function FourthLevelMenu({ menu }) {
  const { title, path, children } = menu

  return (
    <li className={styles.FourthLevelMenuContainer}>
      <a className={styles.FourthLevelMenuTitle} href={path}>
        {title}
      </a>
      <ul>
        {children.map(({ title, path }) => (
          <li key={title + path}>
            <a href={path}>{title}</a>
          </li>
        ))}
      </ul>
    </li>
  )
}

function arrangeSubMenusWithOther(subMenus) {
  const edgeLength = 10

  if (subMenus.length <= edgeLength) return subMenus

  const commonSubMenus = subMenus.slice(0, edgeLength)
  const otherSubMenus = subMenus.slice(edgeLength)
  const otherSubMenuItem = {
    isOther: true,
    title: 'Другое',
    path: '#',
    children: otherSubMenus,
  }

  return [...commonSubMenus, otherSubMenuItem]
}

export function SubMenus({
  num,
  subMenus,
  isFocused,
  hide,
  keepFocused,
  isCustomStore,
  ...props
}) {
  const isMobile = useSelector(isMobileSelector)
  const [isBoxShadow, setIsBoxShadow] = useState(true)
  const [focusIndex, setFocusIndex] = useState(-1)
  let timeout = null

  const subMenusWithOther = arrangeSubMenusWithOther(subMenus)

  const onMouseEnterHandler = (index) => {
    if (isMobile) return

    clearTimeout(timeout)

    if (isCustomStore)
      timeout = setTimeout(() => {
        setFocusIndex(index)
        setIsBoxShadow(true)
      }, 100)
    else {
      setFocusIndex(index)
      setIsBoxShadow(false)
    }
  }

  const onMouseLeaveHandler = () => {
    if (isMobile) return

    clearTimeout(timeout)
    timeout = setTimeout(
      () => {
        setFocusIndex(-1)
        setIsBoxShadow(true)
      },
      isCustomStore ? 100 : 750
    )
  }

  const onClickHandler = (index) => {
    setFocusIndex(index === focusIndex ? -1 : index)
  }

  const subMenusElements = subMenusWithOther.map((subMenu, index) => {
    let path = isCustomStore ? subMenu.path.split('store')[1] : subMenu.path

    return (
      <div key={subMenu.title} className={styles.subMenu}>
        <div
          className={styles.subMenuLabelContainer}
          onMouseEnter={onMouseEnterHandler.bind(null, index)}
          onMouseLeave={onMouseLeaveHandler}
        >
          <a className={styles.subMenuLabel} href={path}>
            {subMenu.title}
          </a>
          <button
            className={classnames(styles.subMenuChevron, {
              [styles.active]: focusIndex === index,
            })}
            onClick={() => onClickHandler(index)}
          >
            <img loading="lazy" src={chevronIcon} alt="chevron icon" />
          </button>
          <ThirdLevelMenu
            menu={subMenu}
            focusIndex={focusIndex}
            menuIndex={index}
            isCustomStore={isCustomStore}
          />
        </div>
      </div>
    )
  })

  return (
    <div
      className={
        isCustomStore ? props.className : styles.subMenusPerspectiveContainer
      }
    >
      <div
        className={classnames(styles.subMenus, styles[`subMenus-${num}`], {
          [styles.focus]: isFocused,
          [styles.boxShadow]: isBoxShadow,
        })}
        onMouseEnter={keepFocused}
        onMouseLeave={hide}
      >
        {subMenusElements}
      </div>
    </div>
  )
}

SubMenus.propTypes = {
  num: PropTypes.number,
  isFocused: PropTypes.bool,
  isActive: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  keepFocused: PropTypes.func.isRequired,
  subMenus: PropTypes.array.isRequired,
}
