export function getRootElement(componentName) {
    return document.querySelector(`[data-react-class="${componentName}"`)
}

export function componentExistsOnPage(componentName) {
    return Boolean(getRootElement(componentName))
}

export function getComponentProps(componentName) {
    const rootEl = getRootElement(componentName)
    const jsonProps = rootEl.getAttribute('data-react-props')
    const props = JSON.parse(jsonProps)

    return props
}
