import React from 'react'

const MenuIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M26 20v2H2v-2h24zm0-7v2H2v-2h24zm0-7v2H2V6h24z"
    />
  </svg>
)

export default MenuIcon
