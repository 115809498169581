"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
exports.theme = {
    fontSize: {
        tiny: '12px',
        small: '14px',
        base: '16px',
        large: '20px',
    },
    colors: {
        black: '#2A3133',
        brand: '#3CB868',
        brand10: 'rgba(60, 184, 104, 0.1)',
        secondary: '#949899',
        secondary20: 'rgba(148, 152, 153, 0.2)',
        grayDark: '#3F4547',
        grayLight: '#DFE0E0',
        grayF: '#f8f9fa',
        grayE: '#e9ecef',
        grayD: '#dee2e6',
        grayC: '#c6c6c6',
        grayB: '#b6b6b6',
        grayA: '#a7a7a7',
        gray9: '#939393',
        gray8: '#848484',
        gray7: '#747272',
        gray6: '#696969',
        gray4: '#4f4f4f',
        gray3: '#323232',
        danger: '#f44336',
        bg: {
            secondary: '#F5F6F6',
        },
    },
};
