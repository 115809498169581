import React, { useContext } from 'react'
import { Context } from '../../index'
import classnames from 'classnames'

import styles from './styles.module.scss'

export default function PhoneRussiaLink(props) {
  const { inHeader } = props
  const { phone } = useContext(Context)
  return (
    <a
      href={'tel:' + phone}
      className={classnames({ [styles.phone]: inHeader })}
    >
      {phone}
    </a>
  )
}
