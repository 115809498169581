import React from "react";
import {Cart} from "../Cart";

import styles from "./vtb-cart-container.module.scss";

export function VTBCartContainer() {
  return (
    <div className={styles.sticky}>
      <Cart />
    </div>
  );
}
