import React from 'react'

const SearchIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24">
    <g transform="translate(-14 -10)">
      <path
        fill="currentColor"
        d="M24.333 13.667a6.667 6.667 0 0 1 5.264 10.758l4.74 4.733-1.176 1.175-4.74-4.733a6.667 6.667 0 1 1-4.089-11.933zm0 1.666a5 5 0 1 0 0 10 5 5 0 0 0 0-10z"
      />
    </g>
  </svg>
)

export default SearchIcon
