import React from 'react'
import {useSelector} from 'react-redux'
import classnames from 'classnames'

import {StickyHeader} from './StickyHeader'
import {VTBCartContainer} from './VTBCartContainer'
import {Pane} from './Pane'
import {Header} from './Header'

import {disabledSearchPages} from '../config'
import {defaultState} from '../defaultState'
import {Context} from '..'

import styles from '../header.module.scss'

const Root = () => {
  const state = useSelector((state) => state)

  const isStickyHeaderDisabled = disabledSearchPages.some((regexp) =>
    regexp.test(state.fullpath)
  )

  return (
    <Context.Provider value={{...defaultState, ...state}}>
      {state.onPlatform !== `vtb` ? (
        <header
          className={classnames(styles.container, {
            [styles.containerVTB]: state.onPlatform === `vtb`,
          })}
        >
          <Pane isCustomStore={state.isCustomStore}/>
          {!isStickyHeaderDisabled && <StickyHeader />}
          <Header />
        </header>
      ) : (
        <VTBCartContainer />
      )}
    </Context.Provider>
  )
}

export default Root
