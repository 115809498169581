import React, { useContext, useMemo } from 'react'
import classnames from 'classnames'
import { Context } from '../../index'
import styles from './user-menu.module.scss'

export function UserMenu() {
  const { userMenu, user } = useContext(Context)

  const userMenuEl = useMemo(
    () =>
      userMenu.map((item) => {
        if (
          (item.admin && !user.admin) ||
          (item.vendor && !user.vendor) ||
          (item.licensee && !user.licensee)
        ) {
          return
        }

        return (
          <li key={item.link + item.label}>
            <a
              href={item.link}
              className={classnames({
                [styles.special]: item.admin || item.vendor || item.licensee,
              })}
            >
              {item.label}
            </a>
          </li>
        )
      }),
    [user.admin, user.vendor, user.licensee, userMenu]
  )

  return <ul className={styles.menu}>{userMenuEl}</ul>
}
