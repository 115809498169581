import React, {useState, useContext, useRef} from "react";
import classnames from "classnames";
import {Context} from "../../index";
import {UserMenu} from "../UserMenu";
import {useOnClickOutside} from "../../../../utils/use-on-click-outside";

import styles from "./user.module.scss";
import chevronIcon from "../../assets/chevron.svg";

export function User() {
  const {user} = useContext(Context);
  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef(null);

  useOnClickOutside(containerRef, () => setIsOpened(false));

  return (
    <div className={styles.container} ref={containerRef}>
      <button
        type="button"
        className={classnames(styles.button, {[styles.active]: isOpened})}
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className={styles.usernameContainer}>{user.name}</div>
        <img loading="lazy"
          className={classnames(styles.chevron, {[styles.active]: isOpened})}
          src={chevronIcon}
        />
      </button>
      {isOpened && <UserMenu />}
    </div>
  );
}
