import React, { useState, useContext, useRef } from 'react'
import loadable from '@loadable/component'
import classnames from 'classnames'
import debounce from '../../../../utils/debounce'
import { useOnClickOutside } from '../../../../utils/use-on-click-outside'
import { RegionsList } from '../RegionsList'
import { Context } from '../../index'
import { changeRegion, loadRegions } from '../../../../services/regions'

import styles from './geopicker.module.scss'
import CloseIcon from '../../../common/Icons/CloseIcon'
import GenericButton from '../../../common/GenericButton'

const AsyncSelect = loadable(() => import('react-select'), {
  resolveComponent: (components) => components.Async,
})

export function GeoPicker() {
  const [isOpen, setIsOpen] = useState(false)
  const { authenticityToken, location } = useContext(Context)
  const containerRef = useRef(null)

  useOnClickOutside(containerRef, () => setIsOpen(false))

  return (
    <div className={styles.container} ref={containerRef}>
      <GenericButton
        className={classnames(styles.button, { [styles.active]: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {location}
      </GenericButton>
      {isOpen && (
        <div className={styles.box}>
          <div className={styles.bar}>
            <AsyncSelect
              className={styles.input}
              onChange={({ value, label, name }) =>
                changeRegion(authenticityToken, {
                  fiasID: value,
                  fullName: label,
                  name,
                })
              }
              loadOptions={debounce(loadRegions, 200, true)}
              loadingMessage={() => `Загрузка...`}
              noOptionsMessage={({ inputValue }) =>
                `По запросу ${inputValue} ничего не найдено`
              }
              placeholder="Населённый пункт"
            />
            <GenericButton
              onClick={() => setIsOpen(false)}
              className={styles.closeButton}
              icon
            >
              <CloseIcon />
            </GenericButton>
          </div>
          <RegionsList />
        </div>
      )}
    </div>
  )
}
