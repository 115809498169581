import React, { useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { pluralize } from '../../../../utils/pluralize'
import { CartList } from '../CartList'
import { Context } from '../../index'

import styles from './cart-content.module.scss'
import CloseIcon from '../../../common/Icons/CloseIcon'
import { isMobileSelector } from '$common/selectors'
import GenericButton from '../../../common/GenericButton'

export function CartContent({ items, close }) {
  const isMobile = useSelector(isMobileSelector)
  const { onPlatform } = useContext(Context)

  const totalAmount = useMemo(() => {
    const total = items.reduce((acc, { amount }) => acc + amount, 0)
    return `${total} ${pluralize(items.length, `вещь`, `вещи`, `вещей`)}`
  }, [items])
  const totalPrice = useMemo(
    () =>
      items.reduce((accumulator, current) => accumulator + current.price, 0),
    [items]
  )
  const itemsElements =
    items.length > 0 ? (
      <CartList items={items} />
    ) : (
      <div className={styles.empty}>Корзина пуста</div>
    )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <div>В корзине {totalAmount}</div>
          <div>на {totalPrice} ₽</div>
        </div>
        {isMobile && (
          <GenericButton onClick={close} className={styles.closeButton} icon>
            <CloseIcon />
          </GenericButton>
        )}
      </div>
      <div className={styles.list}>{itemsElements}</div>
      <div
        className={classnames(styles.checkout, {
          [styles.checkoutVTB]: onPlatform === `vtb`,
        })}
      >
        <a href="/cart" style={{ color: '#ffffff', textDecoration: 'none' }}>
          Оформить заказ
        </a>
      </div>
    </div>
  )
}

CartContent.propTypes = {
  close: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      price: PropTypes.number,
      amount: PropTypes.number,
      picture: PropTypes.string,
    })
  ),
}
