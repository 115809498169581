import React, {useMemo, useContext} from "react";
import classnames from "classnames";
import {Context} from "../../index";
import {changeRegion} from "../../../../services/regions";
import styles from "./regions-list.module.scss";

export function RegionsList() {
  const {authenticityToken, regionsList} = useContext(Context);

  const regionsListEl = useMemo(
    () =>
      regionsList.map(({fiasID, name, bold}) => (
        <li key={fiasID}>
          <button
            type="button"
            onClick={() => changeRegion(authenticityToken, {fiasID})}
          >
            <span className={classnames({[styles.bold]: bold})}>{name}</span>
          </button>
        </li>
      )),
    [authenticityToken, regionsList]
  );

  return <ul>{regionsListEl}</ul>;
}
