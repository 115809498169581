import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./header-wrapper.module.scss";

export function HeaderWrapper({children, className}) {
  return <div className={classnames(styles.header, className)}>{children}</div>;
}

HeaderWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};
