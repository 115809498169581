import {
  changeRegion as changeRegionRequest,
  regionsAutocomplete
} from "../api/common";

export function changeRegion(authenticityToken, {fiasID, fullName, name}) {
  const fias = {
    [`fias_id`]: fiasID,
    [`full_name`]: fullName,
    name
  };

  fetch(changeRegionRequest(authenticityToken, fias)).then(() => {
    window.location.reload();
  });
}

export async function loadRegions(value) {
  const response = await fetch(regionsAutocomplete(value)).then((res) =>
    res.json()
  );
  return response.map((item) => ({
    value: item.id,
    label: item[`full_name`],
    name: item.name
  }));
}
