import React, {useState, useContext, useMemo, useCallback, useRef} from "react";
import {useSelector} from 'react-redux'
import PropTypes from "prop-types";
import classnames from "classnames";
import CloseIcon from "../../../common/Icons/CloseIcon";
import {SubMenus} from "../SubMenus";
import {SidebarPane} from "../SidebarPane";
import {Context} from "../../index";
import {useOnClickOutside} from "../../../../utils/use-on-click-outside";
import {isMobileSelector} from "$common/selectors";

import styles from "./menus.module.scss";
import chevronIcon from "../../assets/chevron.svg";
import GenericButton from "../../../common/GenericButton";

function MenusContainer({children, isOpen, searchMode, close}) {
  const containerRef = useRef(null);
  const isMobile = useSelector(isMobileSelector)
  useOnClickOutside(isMobile && isOpen && containerRef, close);

  return (
    <div
      className={classnames(styles.menusContainer, {[styles.active]: isOpen})}
    >
        <GenericButton
          onClick={close}
          className={styles.closeButton}
          icon
        >
          <CloseIcon />
        </GenericButton>
      <div
        className={classnames(styles.menus, {
          [styles.active]: isOpen,
          [styles.searchMode]: searchMode
        })}
        ref={containerRef}
      >
        {children}
      </div>
    </div>
  );
}

MenusContainer.propTypes = {
  children: PropTypes.array,
  isOpen: PropTypes.bool,
  searchMode: PropTypes.bool,
  close: PropTypes.func
};

export function Menus({searchMode, isOpen, close}) {
  const {menu} = useContext(Context);
  const isMobile = useSelector(isMobileSelector)
  const [focusIndex, setFocusIndex] = useState(-1);
  let focusTimeout = null
  let enterTimeout = null

  function hoverActivationHandler(index) {
    if (isMobile) return

    clearTimeout(focusTimeout);

    if (focusIndex === -1) enterTimeout = setTimeout(() => setFocusIndex(index), 250);
    else setFocusIndex(index);
  }

  const hoverDeactivationHandler = (isImmediate) => {
    clearTimeout(enterTimeout);

    if (isMobile) return

    if (isImmediate) return setFocusIndex(-1);

    focusTimeout = setTimeout(() => setFocusIndex(-1), 750)
  }

  const onClickHandler = (index) => {
    setFocusIndex(index === focusIndex ? -1 : index);
  }

  const menusElements = useMemo(
    () =>
      menu.map((menusItem, index) => {
        return (
          <li key={menusItem.title}>
            <div className={styles.menusItemRow}>
              <a
                className={classnames({[styles.active]: focusIndex === index})}
                href={menusItem.path}
                onMouseEnter={() => hoverActivationHandler(index)}
                onMouseLeave={() => hoverDeactivationHandler()}
                onFocus={() => hoverActivationHandler(index)}
              >
                {menusItem.title}
              </a>
              {menusItem.children && (
                <button
                  className={classnames(styles.chevron, {
                    [styles.active]: focusIndex === index
                  })}
                  onClick={() => onClickHandler(index)}
                >
                  <img loading="lazy" src={chevronIcon} alt="chevron icon" />
                </button>
              )}
            </div>
            {menusItem.children && (
              <SubMenus
                num={index}
                subMenus={menusItem.children}
                isFocused={focusIndex === index}
                hide={() => hoverDeactivationHandler(true)}
                keepFocused={() => hoverActivationHandler(index)}
              />
            )}
          </li>
        );
      }),
    [
      menu,
      focusIndex,
      hoverActivationHandler,
      hoverDeactivationHandler,
      onClickHandler
    ]
  );

  return (
    <MenusContainer
      isOpen={isOpen}
      searchMode={searchMode}
      close={close}
    >
      <nav>
        <ul>{menusElements}</ul>
      </nav>
      <SidebarPane />
    </MenusContainer>
  );
}

// todo: consider taking common large type sets out
Menus.propTypes = {
  searchMode: PropTypes.bool,
  isOpen: PropTypes.bool,
  close: PropTypes.func
};
