import "whatwg-fetch";

export const searchQueryURL = (query, isCustomStore) => `/${isCustomStore ? '' : 'store'}?utf8=✓&q=${query}`;

export const changeRegion = (token, fias) => {
  const fiasQuery = new URLSearchParams();
  Object.keys(fias).forEach((key) => {
    const value = fias[key];
    if (value) {
      fiasQuery.append(`location[${key}]`, fias[key]);
    }
  });
  return new Request(`/region_locales?${fiasQuery.toString()}`, {
    method: `PUT`,
    headers: new Headers({[`x-csrf-token`]: token})
  });
};

export const regionsAutocomplete = (query) =>
  new Request(
    `/checkout_provider/suggest_settlements.json?mode=normal&name=${query}`
  );

export const searchAutocomplete = (query) =>
  new Request(`/search_autocomplete?q=${query}`);

export const removeCartItem = (token, link) => {
  const formData = new FormData();
  formData.append(`_method`, `delete`);
  formData.append(`authenticity_token`, token);
  return new Request(link, {
    method: `POST`,
    body: formData
  });
};
